import React from 'react';
import ReactDOM from "react-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import App from "./App";
import "./index.css"
import { BrowserRouter } from 'react-router-dom';
import Web3 from "web3";

// function getLibrary(provider) {
//   const library = new Web3Provider(provider);
//   library.pollingInterval = 12000;
//   return library;
// }

function getLibrary(provider) {
  const library = new Web3(provider);;
  library.pollingInterval = 12000;
  return library;
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
     <BrowserRouter>
     <App />
     </BrowserRouter>
    
  </Web3ReactProvider>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

