import React from 'react'
import Button from '@mui/material/Button';

export default function Seek(props) {
  return (
    <div style={{marginTop:"100px", textAlign:"center"}}>
        <h1 style={{textAlign:"center"}}>{props.name}</h1>
        {/* <h3><span>Total Credit Balance:</span>{" "}<span>{props.credit} USD</span></h3> */}
        <h3><span>Total Amount Owed:</span>{" "}<span>{props.owed} USD</span></h3>
        <h3><span>Balance Remaining:</span>{" "}<span>{props.remaining && (props.remaining).toFixed(3)} USD</span></h3>
        <h3><span>Available for Withdraw:</span>{" "}<span>{props.withdrawable&& (props.withdrawable).toFixed(3)} USD </span></h3>
        <Button color="secondary" variant="contained" onClick={props.handleWithdraw}>Withdraw</Button>

    </div>
  )
}
