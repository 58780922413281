import React,{useState} from 'react'
import "./Header.css"
import logo from "../Images/Logo.png"
import Button from '@mui/material/Button';

import { useWeb3React } from "@web3-react/core";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { Injected,WalletConnect } from '../connector.js';

export default function Header() {
    const [open, setOpen] = useState(false);
    const { account } = useWeb3React();
   
  return (
    <div className='body'>
  
        <span style={{color:"white",position:"absolute",top:"40%",left:"20px",fontSize:"35px"}}>Reimbursement Panel</span>
        <span style={{position:"absolute",right:"50px",top:"30%"}}>
        <Button  color="inherit" variant='contained' onClick={()=>{setOpen(true)}}>{
            account? `${account.slice(0,7)}...${account.slice(-4)}` : "Connect Wallet"
        }</Button>
        </span>
        <ResponsiveDialog open={open} setOpen={setOpen}/>
    </div>
  )
}








function ResponsiveDialog({open,setOpen}) {



    const { activate,deactivate } = useWeb3React();
    const handleClose = () => {
        setOpen(false);
        activate(WalletConnect)
      };
    
    const handleClose2 = () => {
        setOpen(false);
        activate(Injected)
      };

    const handleDeactivate = () => {
        setOpen(false);
        deactivate()
      };
  
    return (
        <div>
          <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
         Please Choose Wallet
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={handleClose2}
                    >
                      <span>
                        <img
                          width="20px"
                          src="/assets/images/icons/meta-mask.png"
                          alt="img"
                        />
                      </span>
                      MetaMask
                    </span>
                    <br/>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={handleClose}
                    >
                      <span>
                        <img
                          width="20px"
                          src="/assets/images/icons/wallet.png"
                          alt="img"
                        />
                      </span>
                      Wallet Connect
                    </span>
                    <br/>
                    <span 
                     style={{ cursor: "pointer" }}
                    onClick={handleDeactivate}
                    >
                      De-Activate
                    </span>

      
          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
 

        </DialogActions>
      </Dialog>
          
        </div>
     
    );
  }

