import React,{useEffect, useState} from 'react'

import Header from './Components/Header';

import "./App.css"

import 'react-pro-sidebar/dist/css/styles.css';
import Seek from './Components/SeekReward';
import AdminPanel from './Components/AdminPanel';
import { Contract } from '@ethersproject/contracts';
import { Abi1, Abi2, contractAddress1, contractAddress2, tokenAbi, tokenAddress } from './config';
import { useWeb3React } from "@web3-react/core";
import Papa from "papaparse"

// export const getContract = (library, account) => {
// 	const signer = library?.getSigner(account).connectUnchecked();
// 	var contract = new Contract(contractAddress1, Abi1, signer);
// 	return contract;
// };


export default function App() {
    const web3reactContext = useWeb3React()
    const [user,setUser] = useState()
    const [amount,setAmount] = useState()
    const [csv,setCSV] = useState()
    const [toggle,setToggle] = useState()
    const [credit,setCredit] = useState()
    const [owed,setOwed] = useState()
    const [remaining,setRemaining] = useState()
    const [withdrawable,setWithdrawable] = useState()
    const [dollarWithdrawable,setDollarWithdrawable] = useState()
    const [replenishAmount,setReplenishAmount] = useState()
    const [allowance,setAllowance] = useState()

    const [user1,setUser1] = useState()
    const [amount1,setAmount1] = useState()
    const [csv1,setCSV1] = useState()
    const [credit1,setCredit1] = useState()
    const [owed1,setOwed1] = useState()
    const [remaining1,setRemaining1] = useState()
    const [withdrawable1,setWithdrawable1] = useState()
    const [dollarWithdrawable1,setDollarWithdrawable1] = useState()
    const [replenishAmount1,setReplenishAmount1] = useState()
    const [admin,setAdmin] = useState()
    const [allowance1,setAllowance1] = useState()

    var contract1;
    var contract2;
  
  
  useEffect(() => {
     if(web3reactContext.library){
      var tokenContract = new web3reactContext.library.eth.Contract(tokenAbi,tokenAddress);
      contract1 = new web3reactContext.library.eth.Contract(Abi1,contractAddress1);
      const CreditBalance = contract1.methods.owed(web3reactContext.account).call((e,r)=>{setCredit(Number(r)/1000000000000000000)})
      const OwnedBalance = contract1.methods.owed(web3reactContext.account).call((e,r)=>{setOwed(Number(r)/1000000000000000000)})
      const remainingBalance = contract1.methods.balanceRemaining().call({from:web3reactContext.account},(e,r)=>{setRemaining(Number(r)/1000000000000000000)})
      const withdrawableBalance = contract1.methods.Withdrawable().call({from:web3reactContext.account},(e,r)=>{
        setWithdrawable(Number(r)/1000000000000000000)
        contract1.methods.getLatestPrice().call((e,r1)=>{setDollarWithdrawable(Number(r1)*Number(r)/1000000000000000000)})
      })
      const _admin = contract1.methods.Admin().call((e,r)=>{setAdmin(r)})
      const _allowance = tokenContract.methods.allowance(web3reactContext.account,contractAddress1).call((e,r)=>{setAllowance(Number(r)/1000000000000000000)})



      contract2 = new web3reactContext.library.eth.Contract(Abi2,contractAddress2);
      const CreditBalance2 = contract2.methods.owed(web3reactContext.account).call((e,r)=>{setCredit1(Number(r)/1000000000000000000)})
      const OwnedBalance2 = contract2.methods.owed(web3reactContext.account).call((e,r)=>{setOwed1(Number(r)/1000000000000000000)})
      const remainingBalance2 = contract2.methods.balanceRemaining().call({from:web3reactContext.account},(e,r)=>{setRemaining1(Number(r)/1000000000000000000)})
      const withdrawableBalance2 = contract2.methods.Withdrawable().call({from:web3reactContext.account},(e,r)=>{
        setWithdrawable1(Number(r)/1000000000000000000)
        contract2.methods.getLatestPrice().call((e,r1)=>{setDollarWithdrawable1(Number(r1)*Number(r)/1000000000000000000)})
      })
      const _allowance1 = tokenContract.methods.allowance(web3reactContext.account,contractAddress2).call((e,r)=>{setAllowance1(Number(r)/1000000000000000000)})
     }  
   
  }, [web3reactContext.library,toggle])
  


    var userAddress = csv && csv.map((v,e)=>v[0])

    var amounts = csv && csv.map((v,e)=> 
    {
      if(e<csv.length-1)
      return web3reactContext.library?.utils.toWei(v[1],"ether")
    }
 
    )


    var userAddress1 = csv1 && csv1.map((v,e)=>v[0])

    var amounts1 = csv1 && csv1.map((v,e)=> 
    {
      if(e<csv1.length-1)
      return web3reactContext.library?.utils.toWei(v[1],"ether")
    }
 
    )
     
 

    const handleSubmit = async ()=>{
      var count = 0
       const contract1 = new web3reactContext.library.eth.Contract(Abi1,contractAddress1);
      userAddress.pop()
      amounts.pop()
        try {
          contract1.methods.addOwedBulk(userAddress,amounts).send({from:web3reactContext.account}).
          on("confirmation",(e,r)=>{
            if(count===0){
              setToggle(!toggle)
              count++
            }
          })
        } catch (error) {
          console.log("error in handle claim",error)
        }
      
      
    }


    const handleDeposit = async ()=>{
      setReplenishAmount("")
      var count = 0
       const contract1 = new web3reactContext.library.eth.Contract(Abi1,contractAddress1);
     
        try {
          contract1.methods.addFunds(web3reactContext.library?.utils.toWei(replenishAmount,"ether")).send({from:web3reactContext.account}).
          on("confirmation",(e,r)=>{
            if(count===0){
              window.alert("Deposit has been confirmed")
              setToggle(!toggle)
              count++
            }
          })
        } catch (error) {
          console.log("error in handle claim",error)
        }
      
      
    }

    const manualDeposit = async ()=>{
      var count = 0
      setAmount("")
       const contract1 = new web3reactContext.library.eth.Contract(Abi1,contractAddress1);
     
        try {
          contract1.methods.addOwed(user,web3reactContext.library?.utils.toWei(amount,"ether")).send({from:web3reactContext.account}).
          on("confirmation",(e,r)=>{
            if(count===0){
              setToggle(!toggle)
              count++
            }
          })
        } catch (error) {
          console.log("error in handle claim",error)
        }
      
      
    }

    const manualDeposit1 = async ()=>{
      var count = 0
      setAmount1("")
       const contract1 = new web3reactContext.library.eth.Contract(Abi2,contractAddress2);
     
        try {
          contract1.methods.addOwed(user1,web3reactContext.library?.utils.toWei(amount1,"ether")).send({from:web3reactContext.account}).
          on("confirmation",(e,r)=>{
            if(count===0){
              setToggle(!toggle)
              count++
            }
          })
        } catch (error) {
          console.log("error in handle claim",error)
        }
      
      
    }


    const handleWithdraw = async ()=>{
      var count = 0
       const contract1 = new web3reactContext.library.eth.Contract(Abi1,contractAddress1);
     
        try {
          contract1.methods.withdraw().send({from:web3reactContext.account}).
          on("confirmation",(e,r)=>{
            if(count===0){
              setToggle(!toggle)
              count++
            }
          })
        } catch (error) {
          console.log("error in handle claim",error)
        }
      
      
    }


    const handleSubmit1 = async ()=>{
      var count = 0
       const contract2 = new web3reactContext.library.eth.Contract(Abi2,contractAddress2);
      userAddress1.pop()
      amounts1.pop()
        try {
          contract2.methods.addOwedBulk(userAddress1,amounts1).send({from:web3reactContext.account}).
          on("confirmation",(e,r)=>{
            if(count===0){
              setToggle(!toggle)
              count++
            }
          })
        } catch (error) {
          console.log("error in handle claim",error)
        }
      
      
    }


    const handleDeposit1 = async ()=>{
      setReplenishAmount1("")
      var count = 0
       const contract2 = new web3reactContext.library.eth.Contract(Abi2,contractAddress2);
     
        try {
          contract2.methods.addFunds(web3reactContext.library?.utils.toWei(replenishAmount1,"ether")).send({from:web3reactContext.account}).
          on("confirmation",(e,r)=>{
            if(count===0){
              setToggle(!toggle)
              window.alert("Deposit has been confirmed")
              count++
            }
          })
        } catch (error) {
          console.log("error in handle claim",error)
        }
      
      
    }


    const handleWithdraw1 = async ()=>{
      var count = 0
       const contract2 = new web3reactContext.library.eth.Contract(Abi2,contractAddress2);
     
        try {
          contract2.methods.withdraw().send({from:web3reactContext.account}).
          on("confirmation",(e,r)=>{
            if(count===0){
              setToggle(!toggle)
              count++
            }
          })
        } catch (error) {
          console.log("error in handle claim",error)
        }
      
      
    }


    const handleApprove = async ()=>{
      var count = 0
       const tokenContract = new web3reactContext.library.eth.Contract(tokenAbi,tokenAddress);
     
        try {
          tokenContract.methods.approve(contractAddress1,web3reactContext.library?.utils.toWei(replenishAmount,"ether")).send({from:web3reactContext.account}).
          on("confirmation",(e,r)=>{
            if(count===0){
              setToggle(!toggle)
              window.alert("Approval has been made")
              count++
            }
          })
        } catch (error) {
          console.log("error in handle claim",error)
        }
      
      
    }


    const handleApprove1 = async ()=>{
      var count = 0
       const tokenContract = new web3reactContext.library.eth.Contract(tokenAbi,tokenAddress);
     
        try {
          tokenContract.methods.approve(contractAddress2,web3reactContext.library?.utils.toWei(replenishAmount1,"ether")).send({from:web3reactContext.account}).
          on("confirmation",(e,r)=>{
            if(count===0){
              window.alert("Approval has been made")
              setToggle(!toggle)
              count++
            }
          })
        } catch (error) {
          console.log("error in handle claim",error)
        }
      
      
    }

    const handleChange = async event => {
      const file = await event.target.files[0]
      Papa.parse(file, {
        complete: updateData,
        header: false
      });
      // const reader = new window.FileReader()
      // reader.readAsText(file)
      // reader.onloadend = async ()=>{   console.log("reader",reader.result) }
    }

    
  
    function  updateData(result) {
      var data = result.data;
      console.log(data);
      setCSV(data);
    }

    const handleChange1 = async event => {
      const file = await event.target.files[0]
      Papa.parse(file, {
        complete: updateData1,
        header: false
      });
      // const reader = new window.FileReader()
      // reader.readAsText(file)
      // reader.onloadend = async ()=>{   console.log("reader",reader.result) }
    }
  
    function  updateData1(result) {
      var data = result.data;
      console.log(data);
      setCSV1(data);
    }


    window.ethereum?.on("accountsChanged",(r)=>{
      var counter = 0;
      if(counter ===0){
        setToggle(!toggle);
        counter++
      }
    })
  
    window.ethereum?.on("chainChanged", (r) => {
      var counter = 0;
      if (counter == 0) {
        setToggle(!toggle);
        counter++;
      }
    });


    console.log("csv",admin && admin)
    console.log("account",web3reactContext && web3reactContext.account)

  return (
    <div>
        <Header></Header>
        {admin && admin === web3reactContext.account?

          <AdminPanel 
          user={user}
          setUser={setUser}
          amount={amount}
          setAmount={setAmount}
          csv={csv}
          setCSV={setCSV}
          handleSubmit={handleSubmit}
          replenishAmount={replenishAmount}
          setReplenishAmount={setReplenishAmount}
          handleDeposit={handleDeposit}
          handleChange={handleChange}
          allowance={allowance}
          manualDeposit={manualDeposit}
          handleApprove={handleApprove}
          name={"Admin Panel for Seek Reward"}/>: null
        }
        
        
        <div >
        <Seek

        owed={owed}
        credit={credit}
        remaining={remaining}
        withdrawable={withdrawable}
        dollarWithdrawable={dollarWithdrawable}
        float={"left"}
        handleWithdraw={handleWithdraw}
        name={"Seek Rewards Reimbursement Portal"}
        />

        {admin && admin === web3reactContext.account?
          <AdminPanel 
          user={user1}
          setUser={setUser1}
          amount={amount1}
          setAmount={setAmount1}
          csv={csv1}
          setCSV={setCSV1}
          handleSubmit={handleSubmit1}
          replenishAmount={replenishAmount1}
          setReplenishAmount={setReplenishAmount1}
          handleDeposit={handleDeposit1}
          handleChange={handleChange1}
          allowance={allowance1}
          manualDeposit={manualDeposit1}
          handleApprove={handleApprove1}
        name={"Admin Panel for Seek Coin"}/>:null
        }
        
        <Seek 
        owed={owed1}
        credit={credit1}
        remaining={remaining1}
        withdrawable={withdrawable1}
        dollarWithdrawable={dollarWithdrawable1}
        handleWithdraw={handleWithdraw1}
        float={"right"}
        name={"Seek Coin Reimbursement Portal"}
        />
        </div>
        
        
        
      



    </div>
  )
}



  
