import React,{useState} from 'react'

import Button from '@mui/material/Button';

export default function AdminPanel({name,user,setUser,amount,setAmount,setCSV,handleSubmit,replenishAmount,setReplenishAmount,handleDeposit,handleChange,allowance,handleApprove,manualDeposit}) {

    




  return (
    <div style={{marginTop:"100px", textAlign:"center", alignContent:"center"}}>
    <h1>{name}</h1> 
    <div style={{border:"1px solid black", width:"400px",height:"200px",marginLeft:"470px"}}>
    <h4 style={{marginTop:"0px"}}>Manual Entry</h4>
    <input value={user} onChange={(e)=>{setUser(e.target.value)}} placeholder="Enter user address"/>
    <input value={amount} onChange={(e)=>{setAmount(e.target.value)}} placeholder="Enter amount"/>
    <Button color="secondary" variant="contained" onClick={manualDeposit}>Manual Submit</Button>
    <input className="CSV-file-input" type="file" onChange={handleChange}/>
    <Button color="secondary" variant="contained" onClick={handleSubmit}>Submit</Button>
    <h4 style={{marginTop:"5px"}}>Deposit Funds</h4>
    <input value={replenishAmount} onChange={(e)=>{setReplenishAmount(e.target.value)}} placeholder="Enter deposit amount"/>
    {allowance>=replenishAmount? 
        <Button color="secondary" variant="contained" onClick={handleDeposit}>Deposit</Button> :
        <Button color="secondary" variant="contained" onClick={handleApprove}>Approve</Button>
    }

    </div>
    
    </div>
  )
}
